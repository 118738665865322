import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/powered-by-workplay-green.png";
const { REACT_APP_LOGO_URL, REACT_APP_CONTACT_URL } = process.env;

const ContactPage: React.FC = (): JSX.Element => {
  return (
    <section>
      <div className="flex flex-col items-center justify-evenly my-28 animate-fade-in">
        <h1 className="text-2xl font-semibold md:text-4xl my-6">You Won</h1>
        <div className="grid grid-cols-1 gap-8 my-4">
          <Link to="/" className="Link">
            <button className="btn"> PLAY AGAIN </button>
          </Link>
          <a
            href={REACT_APP_CONTACT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn"> CONTACT US</button>
          </a>
        </div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img
            id="powered"
            src={logo}
            className="my-8 h-16"
            alt="workplay-logo"
          />
        </a>
      </div>
    </section>
  );
};

export default ContactPage;
