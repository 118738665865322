import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactPage from "./Components/ContactPage";
import Game from "./Components/Game";
import Play from "./Components/Play";

function App(): JSX.Element {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Play />} />
          <Route path="result" element={<ContactPage />} />
          <Route path="game" element={<Game />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
