import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/workplay-logo-with-shadow.png";

const { REACT_APP_LOGO_URL } = process.env;

const Play: React.FC = (): JSX.Element => {
  return (
    <section className="flex justify-center items-center my-28 animate-fade-in">
      <div className="flex flex-col justify-center items-center my-8">
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={logo}
            alt="workplay.digital"
            className="my-6 h-9 md:h-10"
            id="workplay-logo"
          />
        </a>
        <h1 className="my-3 text-2xl font-semibold md:text-3xl">
          Find the object
        </h1>
        <p className="mb-10 text-xl w-80 md:text-2xl md:w-96">
          Find the objects specified in the image. After you find all 4 objects,
          you win.
        </p>
        <Link to="/game">
          <button id="play" className="btn">
            PLAY
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Play;
