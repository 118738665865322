import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Game.css";
import kitchen from "../assets/kitchen_new.jpg";
import hamburger from "../assets/hamburger.png";
import roastedchicken from "../assets/roasted-chicken.png";
import groceries from "../assets/groceries.png";
import mixing from "../assets/mixing.png";
import dabba from "../assets/TiffinBox.png";
import mixer from "../assets/mixer.png";
import kitchenWare from "../assets/kitchen-ware.png";
import knife from "../assets/butcher-knife.png";
import tool from "../assets/kitchen-tool.png";
import logo from "../assets/workplay-logo-with-shadow.png";
import groceriesOutline from "../assets/groceries-outline.png";
import mixingOutline from "../assets/mixing-outline.png";
const { REACT_APP_LOGO_URL } = process.env;

const objects = [hamburger, roastedchicken, groceriesOutline, mixingOutline];

const Game: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [FoundObject, setFoundObject] = useState({
    hamburger: false,
    groceries: false,
    mixing: false,
    chicken: false,
  });

  // onclick of found object adds border in main image and object container
  const find = (e: React.MouseEvent<HTMLImageElement>): void => {
    const img = e.target as HTMLImageElement;
    img.style.outline = "3px solid green";

    const list = document.querySelectorAll(".objects");
    const Item = Array.from(list).filter((item) => {
      let tag: string | null = null;
      if (
        String(item.id) === "/static/media/hamburger.fa56f8afd6bfffdd2432.png"
      ) {
        tag = "hamburger";
      } else if (
        String(item.id) ===
        "/static/media/roasted-chicken.0049f1c34a728a72b061.png"
      ) {
        tag = "chicken";
      } else if (
        String(item.id) ===
        "/static/media/groceries-outline.233fecfb01a24e1c0ca3.png"
      ) {
        tag = "groceries";
      } else {
        tag = "mixing";
      }
      return tag === img.id;
    });

    const targetItem = Item[0] as HTMLElement | undefined;
    if (targetItem) {
      targetItem.classList.add("border-obj");
      setFoundObject((prevFoundObject) => ({
        ...prevFoundObject,
        [img.id]: true,
      }));
    }
  };

  if (
    FoundObject.hamburger === true &&
    FoundObject.groceries === true &&
    FoundObject.mixing === true &&
    FoundObject.chicken === true
  ) {
    setTimeout(() => navigate("/result"), 300);
  }

  useEffect(() => {
    let Images = document.getElementsByClassName("small-image");
    setTimeout(() => {
      Array.from(Images).map((Element) => {
        Element.classList.add("visible");
      });
    }, 2000);
  }, []);

  return (
    <div className="my-28 flex flex-col justify-center items-center w-auto animate-fade-in md:my-2">
      <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img
          src={logo}
          alt="workPlay.digital"
          className="h-9 md:h-10"
          id="workplay-logo"
        />
      </a>
      <div className="object-container">
        {objects.map((el, index) => {
          return (
            <img
              src={el}
              id={el}
              loading="lazy"
              key={index}
              alt="box"
              className="objects container"
            ></img>
          );
        })}{" "}
      </div>
      <div className="image-container ">
        <img
          src={kitchen}
          className="kitchen "
          loading="lazy"
          alt="kitchen"
        ></img>
        <img
          src={mixing}
          id="mixing"
          loading="lazy"
          className="small-image"
          alt="mixing-bowl"
          onClick={find}
        ></img>
        <img
          src={dabba}
          id="dabba"
          loading="lazy"
          alt="tiffin box"
          className="small-image"
        ></img>
        <img
          src={kitchenWare}
          id="kitchenware"
          loading="lazy"
          alt="kitchen ware"
          className="small-image"
        ></img>
        <img
          src={hamburger}
          loading="lazy"
          id="hamburger"
          className="small-image"
          alt="hamburger"
          onClick={find}
        ></img>
        <img
          src={knife}
          id="knife"
          loading="lazy"
          alt="knife"
          className="small-image"
        ></img>
        <img
          src={tool}
          id="tool"
          loading="lazy"
          className="small-image"
          alt="tool"
        ></img>
        <img
          src={groceries}
          id="groceries"
          className="small-image"
          alt="gorceries"
          onClick={find}
        ></img>
        <img
          src={roastedchicken}
          id="chicken"
          className="small-image"
          alt="chicken"
          onClick={find}
        ></img>
        <img src={mixer} id="mixer" className="small-image" alt="mixer"></img>
      </div>
    </div>
  );
};

export default Game;
